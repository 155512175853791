.home-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(180deg, white, #b89ef5);
  color: #333;
  padding: 0 10%;
  overflow: hidden;
}

/* Animation specific to Home page */
.home-animate {
  opacity: 0;
  transform: translateY(50px);
  animation: slideInHome 1.5s ease-out forwards;
}

/* Slide-in and fade-in animation */
@keyframes slideInHome {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Content Box Styles */
.content-box {
  width: 100%;
  max-width: 1200px;
  background-color: rgba(30, 30, 47, 0.95);
  padding: 3rem;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5);
  margin: 0 auto;

  /* Ensure the final state is always visible */
  opacity: 1;
  transform: translateY(0);
}

/* Text Content */
.intro {
  flex: 1;
  text-align: left;
  margin-right: 3rem;
}

.greeting {
  font-size: 1.2rem;
  color: #d1c4fc;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8);
}

.intro h1 {
  font-size: 3rem;
  color: #ffffff; /* Changed to white */
  margin: 0.5rem 0;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8), 0px 0px 6px rgba(255, 255, 255, 0.7); /* Adjusted for white */
}

.intro h2 {
  font-size: 2rem;
  color: #b89ef5; /* Consistent purple color */
  margin: 0.5rem 0;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8);
}

.intro p {
  margin: 1.5rem 0;
  font-size: 1rem;
  color: #e0e0e0;
  line-height: 1.5;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8);
}

/* Social Links */
.social-links {
  display: flex;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.social-links a {
  padding: 0.5rem 1rem;
  border: 2px solid #b89ef5;
  color: #b89ef5;
  text-decoration: none;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.social-links a:hover {
  background: #b89ef5;
  color: #1e1e2f;
}

/* Button Styles */
.download-cv-button {
  display: inline-block;
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  background: linear-gradient(135deg, #6a5acd, #b89ef5);
  border: none;
  border-radius: 8px;
  text-decoration: none;
  transition: all 0.3s ease;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.download-cv-button:hover {
  transform: translateY(-3px);
  background: linear-gradient(135deg, #7a6bf5, #9d86ff);
}

/* Circular Neon Profile Picture */
.profile-picture {
  flex: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
}

.neon-circle {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  background: linear-gradient(135deg, #6a5acd, #b89ef5);
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 30px #b89ef5, 0 0 60px #6a5acd;
}

.neon-circle img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

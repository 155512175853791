.contact-container {
  background: linear-gradient(180deg, white, #b89ef5); /* Same gradient as Home */
  padding: 2rem 5%;
  color: #fff;
  text-align: center;
}

/* Removed Box Background */
.content-box {
  max-width: 1200px;
  margin: 0 auto;
  animation: fadeInUp 1s ease-in-out;
}

/* Fade-in Animation */
@keyframes fadeInUp {
  from {
    transform: translateY(50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Header Section */
.contact-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 2rem;
}

.contact-intro {
  flex: 1;
}

.contact-intro h1 {
  font-size: 2.5rem;
  color: #6a0dad; /* Vibrant purple for visibility */
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.8); /* Subtle shadow for readability */
}

.contact-intro p {
  font-size: 1.2rem;
  line-height: 1.6;
  color: #e0e0e0; /* Light gray for readability */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5); /* Subtle shadow for contrast */
}

.contact-image img {
  width: 100%;
  max-width: 300px;
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3); /* Add shadow to make the image pop */
}

/* Form Section */
.contact-form-container {
  margin-top: 2rem;
  padding-top: 2rem;
  border-top: 1px solid #6a5acd; /* Purple border */
}

.contact-form-container h2 {
  font-size: 2rem;
  color: #6a0dad; /* Vibrant purple */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8); /* Subtle shadow for readability */
  margin-bottom: 1rem;
}

/* Contact Info Links */
.contact-info p {
  font-size: 1rem;
  margin-bottom: 0.5rem;
  color: #e0e0e0; /* Light gray */
}

.contact-info a {
  color: #6a5acd;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  transition: color 0.3s ease;
}

.contact-info a .icon {
  font-size: 1.5rem;
}

.contact-info a:hover {
  color: #b89ef5;
}

/* Contact Form */
.contact-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
}

.form-group {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.form-group input,
.form-group textarea {
  flex: 1;
  padding: 0.8rem;
  border: 1px solid #6a5acd;
  border-radius: 10px;
  font-size: 1rem;
  background-color: rgba(255, 255, 255, 0.85); /* Light background for inputs */
  color: #333; /* Dark text for readability */
}

.form-group textarea {
  resize: none;
}

.form-group input:focus,
.form-group textarea:focus {
  outline: none;
  border-color: #b89ef5;
}

/* Submit Button */
.submit-button {
  background-color: #6a5acd;
  color: #fff;
  padding: 0.8rem 1.5rem;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.submit-button:hover {
  background-color: #b89ef5;
  color: #fff;
}

/* Success Message */
.success-message {
  color: #6a5acd; /* Purple color for consistency */
  font-size: 1rem;
  margin-top: 1rem;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .contact-header {
    flex-direction: column;
    text-align: center;
  }

  .contact-intro {
    margin-right: 0;
  }

  .contact-image img {
    max-width: 250px;
  }

  .form-group {
    flex-direction: column;
  }
}

@media (max-width: 768px) {
  .contact-intro h1 {
    font-size: 2rem;
  }

  .contact-intro p {
    font-size: 1rem;
  }

  .submit-button {
    font-size: 0.9rem;
    padding: 0.5rem 1rem;
  }

  .contact-form-container h2 {
    font-size: 1.8rem;
  }
}

.project-detail-container {
  padding: 3rem 5%; /* Reduce padding for more space */
  background: linear-gradient(180deg, white, #b89ef5); /* Same gradient as Home */
  color: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Content Box - Removed background box */
.content-box {
  width: 100%;
  max-width: 1200px;
  padding: 3rem;
  text-align: center;
  animation: fadeInUp 1s ease-in-out;
}

/* Fade-in animation */
@keyframes fadeInUp {
  from {
    transform: translateY(50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.content-box h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: #6a0dad; /* Vibrant purple for visibility */
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.8); /* Subtle shadow for readability */
}

.content-box .description {
  font-size: 1.2rem;
  line-height: 1.8;
  margin: 2rem 0;
  color: #e0e0e0; /* Light gray for readability */
  text-align: left; /* Align text to the left for readability */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5); /* Subtle shadow for readability */
}

/* Image Gallery */
.image-gallery {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  margin-top: 2rem;
}

.image-item {
  flex: 1;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
}

.image-item img {
  width: 100%;
  height: auto;
  object-fit: contain;
  transition: transform 0.5s ease;
}

.image-item:hover img {
  transform: scale(1.05); /* Slight zoom on hover */
}

/* PDF Viewer */
.pdf-viewer {
  margin-top: 2rem;
  text-align: center;
}

.pdf-viewer h2 {
  font-size: 1.8rem;
  margin-bottom: 1rem;
  color: #6a0dad; /* Vibrant purple for visibility */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8); /* Subtle shadow for readability */
}

.pdf-viewer embed {
  border: 2px solid #6a5acd;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  width: 100%;
  max-width: 1200px;
  height: 900px;
  margin: 0 auto;
}

/* PDF Gallery for Multiple PDFs */
.pdf-gallery {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  margin-top: 2rem;
}

.pdf-item {
  flex: 1;
  border: 2px solid #6a5acd;
  border-radius: 10px;
  padding: 1rem;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  text-align: center;
}

.pdf-item h2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #6a0dad; /* Vibrant purple for visibility */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8); /* Subtle shadow for readability */
}

.pdf-item embed {
  width: 100%;
  height: 600px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

/* Download Button */
.download-button {
  display: inline-block;
  margin-top: 10px;
  padding: 10px 20px;
  color: #fff;
  background-color: #6a5acd;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.download-button:hover {
  background-color: #b89ef5;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .content-box {
    padding: 2rem;
  }

  .image-gallery {
    flex-direction: column;
    gap: 2rem;
  }

  .image-item img {
    height: auto;
  }

  .pdf-viewer embed {
    width: 100%;
    height: 600px;
  }

  .pdf-gallery {
    flex-direction: column;
  }

  .pdf-item embed {
    height: 500px;
  }
}

.about-container {
  padding: 3rem 10%;
  background: linear-gradient(180deg, white, #b89ef5); /* Same gradient as Home */
  color: #333; /* Adjusted text color for contrast */
  min-height: 100vh;
  text-align: center;
  overflow: hidden; /* Prevent scrolling during animation */
}

.about-header {
  font-size: 2.5rem;
  margin-bottom: 2rem;
  color: #6a0dad; /* Vibrant purple for better visibility */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8); /* Subtle shadow for visibility */
}

.about-container-box {
  border: 3px solid #6a5acd; /* Dark purple border for the container */
  padding: 1.5rem;
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.85); /* Lighter background for better readability */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
  animation: slideInFromBottom 1.5s ease-in-out; /* Slide-in animation */
}

.about-grid {
  display: flex;
  justify-content: space-between;
  gap: 1.5rem; /* Slightly larger gap for better spacing */
}

.about-card {
  position: relative;
  overflow: hidden;
  border-radius: 15px;
  flex: 1;
  max-width: 30%;
  height: 400px; /* Set a fixed height */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  background: rgba(255, 255, 255, 0.9); /* Lighter card background for contrast */
}

.about-card:hover {
  transform: translateY(-5px); /* Lift effect on hover */
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.5); /* Enhanced hover shadow */
}

.about-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.about-card:hover img {
  transform: scale(1.1); /* Zoom in effect on hover */
}

.about-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(30, 30, 47, 0.9); /* Darker overlay for better contrast */
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.about-card:hover .about-overlay {
  opacity: 1; /* Show overlay on hover */
}

.about-text {
  text-align: center;
  color: #f5f5f5; /* Lighter text color for better readability */
  padding: 1rem;
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.7); /* More prominent shadow for better visibility */
}

.about-text h2 {
  font-size: 1.8rem; /* Slightly larger heading size */
  margin-bottom: 1rem;
  color: #ffffff; /* White color for maximum contrast */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.9); /* Stronger shadow for visibility */
}

.about-text p {
  font-size: 1rem;
  line-height: 1.8;
  color: #dddddd; /* Light gray for improved readability */
  max-width: 90%; /* Restrict width for better readability */
  margin: 0 auto;
}

/* Slide-in Animation for Container */
@keyframes slideInFromBottom {
  from {
    transform: translateY(100px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Responsive Design */
@media (max-width: 1024px) {
  .about-grid {
    gap: 1rem; /* Adjust grid gap for smaller screens */
  }
}

@media (max-width: 768px) {
  .about-grid {
    flex-direction: column; /* Stack cards vertically */
    gap: 2rem;
  }

  .about-card {
    max-width: 100%;
    height: 300px; /* Adjust height for smaller screens */
  }
}
